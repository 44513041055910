import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import lottie from "lottie-web";

import { Card } from "react-bootstrap";
import HomeHero from "../components/home-hero";
import StarLogo from "../images/icons/star.svg";
import FacebookLogo from "../images/bi_facebook.svg";
import XLogo from "../images/logo 1.svg";
import InstaLogo from "../images/Frame 62.svg";
import ContactForm from "../components/contactForm";
import HeroOther from "../components/hero-other";

const GolfProsPAge = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "green-fees-4" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img1: wpMediaItem(title: { eq: "Pantry" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img2: wpMediaItem(title: { eq: "Tanishq" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img3: wpMediaItem(title: { eq: "Contact-New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery1: wpMediaItem(title: { eq: "green-fees-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery2: wpMediaItem(title: { eq: "green-fees-3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery3: wpMediaItem(title: { eq: "green-fees-4" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery4: wpMediaItem(title: { eq: "green-fees-5" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery5: wpMediaItem(title: { eq: "green-fees-6" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery6: wpMediaItem(title: { eq: "green-fees-7" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery7: wpMediaItem(title: { eq: "green-fees-8" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			# allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
			#   nodes {
			#     localFile {
			#       childImageSharp {
			#         gatsbyImageData(
			#           formats: [AUTO, WEBP]
			#           quality: 100
			#           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			#           layout: CONSTRAINED
			#           placeholder: BLURRED
			#         )
			#       }
			#     }
			#     caption

			#     permalink
			#     timestamp
			#   }
			# }

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Golf Pros",
				item: {
					url: `${siteUrl}/golf-pros`,
					id: `${siteUrl}/golf-pros`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Golf Pros | Toot Hill Golf Course"
				description="At Toot Hill our professionals bring passion and expertise to create an unforgettable golfing experience."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/golf-pros`,
					title: "Golf Pros | Toot Hill Golf Course",
					description:
						"At Toot Hill our professionals bring passion and expertise to create an unforgettable golfing experience.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<HeroOther
						smallHeading="Our Pros"
						title="Professional coaching"
						description={
							<>
								At Toot Hill our professionals bring passion and expertise to
								create an unforgettable
								<br className="d-none d-md-block" /> golfing experience. Whether
								you're a beginner or a seasoned golfer, our dedicated
								<br className="d-none d-md-block" />
								team of pros are here to guide you on your journey to mastery.
							</>
						}
						button1Text={null}
						button2Text={null}
						// button1Link="https://bookings.toothillgolfclub.co.uk/"
						// button2Text="Course overview"
						// button2Link="/course-overview"
						backgroundImage={
							data.heroImg.localFile.childImageSharp.gatsbyImageData
						}
					/>

					<section className="py-5 py-lg-7">
						<Container>
							<Row className="pb-4 text-center">
								<Col>
									{" "}
									<p className="text-uppercase text-secondary pb-2 mb-0 ssp-semibold">
										Head PGA Professional
									</p>
									<h2 className=" text-primary mb-3 ">Mark Bishop</h2>
								</Col>
							</Row>
							<Row className="pb-4 g-5 gx-lg-7">
								<Col lg={7}>
									<p className="text-primary">
										Mark has been the Head Professional here at Toot Hill Golf
										Club for 27 years and was the Captain of The PGA in Essex in
										2014.
									</p>

									<p className="text-primary">
										Mark offers a well-stocked Proshop offering the very highest
										quality golf products and equipment from leading suppliers
										such as Titleist, Callaway, Odyssey, Foot-Joy, Eco,
										Pro-Quip, and Sun Mountain. Mark also offers a very
										efficient club re-gripping service.
									</p>
									<p className="text-primary">
										Mark is very passionate about coaching and helping you to
										reach your potential and play your best golf whether you are
										a beginner or an elite player.
									</p>
								</Col>
								<Col lg={5} className="text-primary">
									<p className="text-primary">
										Lessons cover all aspects of the game and can be tailored to
										individual needs. Lessons can cover:
									</p>
									<ul className="text-primary">
										<li className="text-primary">Putting</li>
										<li className="text-primary">Chipping</li>
										<li className="text-primary">Pitching</li>
										<li className="text-primary">Bunker play</li>
										<li className="text-primary">Short game from 100 yards</li>
										<li className="text-primary">Iron play</li>
										<li className="text-primary">Driving</li>
										<li className="text-primary">
											On-course playing lessons also available
										</li>
									</ul>
								</Col>
							</Row>
							<Row>
								{" "}
								<Col className="text-center">
									<p className="text-primary">
										For more information, please get in touch by calling Mark on{" "}
										<a className="primary-link" href="tel:07799 882590">
											07799 882590
										</a>{" "}
										or email{" "}
										<a
											className="primary-link"
											href="mailto:markbishoppga@btopenworld.com"
										>
											markbishoppga@btopenworld.com
										</a>
										.
									</p>

									<Button
										style={{ borderRadius: "0px" }}
										variant="outline-primary"
										href="mailto:markbishoppga@btopenworld.com"
										className="py-3 w-100 w-md-auto mb-4 mb-md-0 mt-3 px-6 "
									>
										Get in touch
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 bg-primary py-lg-7">
						<Container>
							<Row className="pb-4 text-center">
								<Col>
									<p className="text-uppercase text-secondary pb-2 mb-0 ssp-semibold">
										PGA Professional
									</p>
									<h2 className="mb-3 text-white">Bobby Keeble</h2>
								</Col>
							</Row>
							<Row className="g-5 gx-lg-7 align-items-center pb-4">
								<Col lg={7}>
									<p className="text-white">
										Bobby had a successful amateur career representing England
										for 4 years then turning Professional and competing on the
										Europro Tour. He has experienced traveling the world,
										competing on the world's top golf courses, and has been
										coached by the very best golf instructors.
									</p>

									<p className="text-white">
										After years of expert knowledge firsthand and realizing what
										it takes to become the best version of a golfer, Bobby can
										put this information towards helping others. Bobby believes
										in teaching the person and not the method, a prime example
										of that is looking on the PGA Tour and seeing so many
										different golf swings and they all produce unbelievable
										outcomes.
									</p>
								</Col>
								<Col lg={5} className="text-white">
									<p className="text-white">
										Get in touch to book a lesson, Bobby would love to help your
										game in any department. Lessons include:
									</p>
									<ul className="text-white">
										<li className="text-white">Putting</li>
										<li className="text-white">Chipping</li>
										<li className="text-white">Pitching</li>
										<li className="text-white">Iron Play</li>
										<li className="text-white">Off the Tee</li>
										<li className="text-white">Mental performance</li>
										<li className="text-white">Course management</li>
									</ul>
								</Col>
							</Row>
							<Row>
								<Col className="text-center">
									{" "}
									<p className="text-white">
										For more information, please contact Bobby on{" "}
										<a className="white-link" href="tel:07545 144443">
											07545 144443
										</a>{" "}
										or email{" "}
										<a
											className="white-link"
											href="mailto:bobbykeeble@hotmail.co.uk"
										>
											bobbykeeble@hotmail.co.uk
										</a>
										.
									</p>
									<Button
										style={{ borderRadius: "0px" }}
										variant="outline-white"
										href="mailto:bobbykeeble@hotmail.co.uk"
										className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-3 px-6 "
									>
										Get in touch
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 bg-white py-lg-7">
						<Container>
							<Row className="g-5 gx-lg-7 align-items-center justify-content-center">
								<Col lg={8}>
									<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
										Where is toot hill?
									</p>
									<h2 className="line-heading-dark text-center mb-5 fs-1">
										Directions to Toot Hill Golf Club
									</h2>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row className="g-5 h-lg-100">
										<Col style={{ minHeight: "100%" }} lg={4}>
											<div className="bg-primary h-100 py-5 px-4 px-xl-6 text-center">
												<h3 className="text-uppercase text-white fs-4 pb-3">
													From M11
												</h3>
												<p className="text-white ">
													Leave at junction 7 Harlow and take the A414 towards
													Chelmsford. After 1.2 miles you come to a roundabout
													take the 1st exit (A414 towards Chelmsford). After 1.2
													miles you reach the Talbot roundabout go straight over
													(2nd exit). After 1.0 miles turn right into Blake Hall
													Road, after 1.1 miles turn right into Toot Hill Road.
													Continue into Toot Hill Village, just past the Green
													Man Pub take the left turn towards Stanford Rivers.
													The Golf Course is 300 yards on the right hand side.
												</p>
											</div>
										</Col>
										<Col style={{ minHeight: "100%" }} lg={4}>
											<div className="bg-primary h-100 py-5 px-4 px-xl-6 text-center">
												<h3 className="text-uppercase text-white fs-4 pb-3">
													From Brentwood
												</h3>
												<p className="text-white ">
													From Wilsons Corner take the A128 towards Ongar, after
													6.6 miles you reach a mini roundabout turn left (A113)
													towards Chigwell/Stanford Rivers. After 1.5 miles turn
													right into Church Road. The Golf Course is 1.8 miles
													on the left hand side
												</p>
											</div>
										</Col>
										<Col style={{ minHeight: "100%" }} lg={4}>
											<div className="bg-primary h-100 py-5 px-4 px-xl-6 text-center">
												<h3 className="text-uppercase text-white fs-4 pb-3">
													From Abridge
												</h3>
												<p className="text-white ">
													Take the A113 towards Ongar passing the Stapleford
													Airfield on the right hand side. At the Passingford
													Bridge roundabout turn left 1st exit (A113 towards
													Ongar). After 3 miles turn left into Church Road. The
													Golf Course is 1.8 miles on the left hand side.
												</p>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 pb-lg-7">
						<Container>
							<Row className="">
								<Col lg={8}>
									<p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
										Address
									</p>
									<h2 className="  text-dark-green mb-5 fs-4">
										Toot Hill Golf Club, School Road, Toot Hill, Ongar, Essex,
										CM5 9PU
									</h2>
								</Col>
							</Row>

							<Row>
								<Col>
									<iframe
										className="position-relative google-map"
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2473.0311425588225!2d0.1911667763615362!3d51.695875196917385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d897e47c2e754f%3A0xb7f81d8f596b4b47!2sToot%20Hill%20Golf%20Club!5e0!3m2!1sen!2suk!4v1695664623164!5m2!1sen!2suk"
										width="100%"
										style="border:0;"
										allowfullscreen=""
										loading="lazy"
										referrerpolicy="no-referrer-when-downgrade"
									></iframe>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default GolfProsPAge;
